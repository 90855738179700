export const emailValidation = (value: string) => {
    let regex = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm

    if (value.match(regex)) {
        return true
    } else {
        return false
    }
}

export const phoneValidation = (value: string) => {
   if (value.length === 14) {
        return true
    } else {
        return false
    }
}

export const setPhoneMask = (input: any) => {
    input.value = input.value
        .replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{3})(\d)/, '$1) $2')
        .replace(/(\d{3})(\d{1,5})/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
}
