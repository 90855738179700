import React from 'react'
import Button from 'react-bootstrap/Button'
import MultiStep from './components/multistep'
import './App.css'

function App() {
    return (
        <div className="App">
            <Button
                variant="primary"
                className="open-multistep-modal"
            >Open modal</Button>
            <MultiStep/>
        </div>
    );
}

export default App;
